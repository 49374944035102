import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl
} from "gatsby-plugin-intl";
import React from "react";
import Helmet from "react-helmet";
import Container from "../components/Container";
import { circleSmall, waveHorizontalLarge } from "../components/Images";
import Layout from "../components/Layout";
import styled from "../styling/styled";
import { theme } from "../styling/theme";

const ContactContent = styled.div`
  padding-top: 120px;
  padding-bottom: 60px;
  background-size: 160px 17px, 160px 17px, 17px 17px, 17px 47px;
  background-image: url(${waveHorizontalLarge}), url(${waveHorizontalLarge}),
    url(${circleSmall});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 277px) top 90px,
    right calc(${theme.container.background} - 320px) top 150px,
    left calc(${theme.container.background} - 127px) bottom 18%;

  ${[theme.media.mobileMenuVisible]} {
    padding-top: 75px;
    padding-bottom: 30px;
    min-height: 0;
  }
`;

const Text = styled.div`
  h2 {
    font-size: 18px;
    margin-bottom: 0.35em;
  }
`;

class TermsPage extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <>
        <Helmet
          title={intl.formatMessage({ id: "pages.terms.title1" })}
          meta={[
            {
              name: "description",
              content: intl.formatMessage({ id: "pages.terms.description" })
            },
            {
              property: "og:title",
              content: intl.formatMessage({ id: "pages.terms.title1" })
            },
            {
              property: "og:description",
              content: intl.formatMessage({ id: "pages.terms.description" })
            }
          ]}
        >
          <html lang={intl.locale} />
        </Helmet>
        <Layout>
          <ContactContent>
            <Container>
              <Text>
                <h1>
                  <FormattedMessage id="pages.terms.title1" />
                </h1>
                <h2>
                  <FormattedMessage id="pages.terms.title2" />
                </h2>
                <p>
                  <FormattedHTMLMessage id="pages.terms.text1" />
                </p>

                <h2>
                  <FormattedMessage id="pages.terms.title3" />
                </h2>

                <p>
                  <FormattedHTMLMessage id="pages.terms.text2" />
                </p>

                <p>
                  <FormattedMessage id="pages.terms.text3" />
                </p>

                <h2>
                  <FormattedMessage id="pages.terms.title4" />
                </h2>

                <p>
                  <FormattedMessage id="pages.terms.text4" />
                </p>

                <p>
                  <FormattedHTMLMessage id="pages.terms.text5" />
                </p>

                <h2>
                  <FormattedMessage id="pages.terms.title5" />
                </h2>

                <p>
                  <FormattedHTMLMessage id="pages.terms.text6" />
                </p>

                <h2>
                  <FormattedMessage id="pages.terms.title6" />
                </h2>

                <p>
                  <FormattedMessage id="pages.terms.text7" />
                </p>

                <h2>
                  <FormattedMessage id="pages.terms.title7" />
                </h2>

                <p>
                  <FormattedMessage id="pages.terms.text8" />
                </p>

                <p>
                  <FormattedMessage id="pages.terms.text9" />
                </p>

                <h2>
                  <FormattedMessage id="pages.terms.title8" />
                </h2>

                <p>
                  <FormattedMessage id="pages.terms.text10" />
                </p>

                <h2>
                  <FormattedMessage id="pages.terms.title9" />
                </h2>

                <p>
                  <FormattedMessage id="pages.terms.text11" />
                </p>

                <h2>
                  <FormattedMessage id="pages.terms.title10" />
                </h2>

                <p>
                  <FormattedHTMLMessage id="pages.terms.text12" />
                </p>
              </Text>
            </Container>
          </ContactContent>
        </Layout>
      </>
    );
  }
}

export default injectIntl(TermsPage);
